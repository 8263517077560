import { selectMultiple } from "../../consts/description";
import { isRequired } from "../../consts/required";
import { yes, no, dontknow, yes_no } from "../../consts/yes_no";

const s1q1 = {
  type: "multipletext",
  name: "q7s1q1",
  title: {
    default: "How many days has your child had?",
    gr: "Πόσες μέρες είχε το παιδί σας?",
    fr: "Combien de jours l’enfant  a-t-il/elle eu?",
    de: "",
    ka: "რამდენი დღის მანძილზე აღენიშნებოდა თქვენს შვილს ",
    fi: "Kuinka monta päivää lapsellasi oli",
  },
  isRequired: isRequired,
  items: [
    {
      name: "cough",
      title: {
        default: "a) Cough (days)",
        gr: "α) Βήχα (μέρες)",
        fr: "a) de la toux (jours)",
        de: "",
        ka: "ა) ხველა (დღე)",
        fi: "a) Yskää (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
    {
      name: "shortness_of_breath",
      title: {
        default: "b) Shortness of breath (days)",
        gr: "β) Δυσκολία στην αναπνοή (μέρες)",
        fr: "b) un essoufflement (jours)",
        de: "",
        ka: "ბ) ქოშინი (დღე)",
        fi: "b) Hengenahdistusta (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
    {
      name: "runny_nose",
      title: {
        default: "c) Runny nose (days)",
        gr: "γ) Συνάχι (μέρες)",
        fr: "c) Nez qui coule (jours)",
        de: "",
        ka: "გ) სურდო (დღეები)",
        fi: "c) Vuotava nenä (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
    {
      name: "stuffy_nose",
      title: {
        default: "d) Stuffy Nose (days)",
        gr: "δ) Μπουκωμένη μύτη (μέρες)",
        fr: "d) Nez bouché (jours)",
        de: "",
        ka: "დ) ცხვირის გაჭედვა (დღეები)",
        fi: "d) Tukkoinen nenä (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
    {
      name: "sneezing",
      title: {
        default: "e) Sneezing (days)",
        gr: "ε) Συχνά φταρνίσματα (μέρες)",
        fr: "e) Éternuement (jours)",
        de: "",
        ka: "ე) ცემინება (დღეები)",
        fi: "e) Aivastelu (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
    {
      name: "itchy_nose",
      title: {
        default: "f) Itchy nose (days)",
        gr: "ζ) Φαγούρα (μέρες)",
        fr: "f) Démangeaisons du nez (jours)",
        de: "",
        ka: "ვ) ცხვირის ქავილი (დღეები)",
        fi: "f) Kutiseva nenä (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
    {
      name: "sore_throat",
      title: {
        default: "g) Sore Throat (days)",
        gr: "η) Πονόλαιμο (μέρες)",
        fr: "g) Maux de gorge (jours)",
        de: "",
        ka: "თ) ყელის ტკივილი (დღეები)",
        fi: "g) Kurkkukipu (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
    {
      name: "wheezing",
      title: {
        default: "h) Wheezing (days)",
        gr: "θ) Συριγμό/Σφύριγμα/Βράσιμο στο στήθος (μέρες)",
        fr: "h) Sifflements (jours)",
        de: "",
        ka: "ი) ხიხინი (დღეები)",
        fi: "h) Vinkuminen (päivää)",
      },
      inputType: "number",
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 0 to 90",
          minValue: 0,
          maxValue: 90,
        },
      ],
      isRequired: isRequired,
    },
  ],
};

const s1q2 = {
  type: "radiogroup",
  name: "q7s1q2",
  title: {
    default:
      "Have the symptoms limited your child’s ability to run, exercise, or play sports?",
    gr: "Περιορίζουν τα συμπτώματα το παιδί σας όταν τρέχει ή αθλείται;",
    fr: "Les symptômes ont-ils gêné votre enfant lorsqu'il/elle courait, faisait de l'exercice ou du sport ?",
    de: "",
    ka: "აღნიშნულმა სიმპტომებმა შეზღუდა თუ არა თქვენი შვილის სირბილის, სპორტის ან სხვა ფიზიკური აქტივობის უნარი?",
    fi: "Rajoittavatko oireet lastasi, kun hän juoksee, harrastaa liikuntaa tai urheilee?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q2a = {
  type: "text",
  name: "q7s1q2a",
  title: {
    default: "If yes, how many days?",
    gr: "Αν ναι, πόσες μέρες;",
    fr: "Si oui, combien de jours ?",
    de: "",
    ka: "“კი“ პასუხის შემთხვევაში, რამდენი დღე?",
    fi: "Jos kyllä, kuinka monta päivää?",
  },
  inputType: "number",
  isRequired: isRequired,
  visibleIf: " {q7s1q2} == 'yes' ",
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 365",
      minValue: 1,
      maxValue: 365,
    },
  ],
};

const s1q3 = {
  type: "radiogroup",
  name: "q7s1q3",
  title: {
    default: "Has your child missed school due to physical discomfort?",
    gr: "Έχει απουσιάσει το παιδι σας από το σχολείο λόγω σωματικής δυσφορίας/ενόχλησης;",
    fr: "Votre enfant a-t-il manqué l'école en raison d'une gêne physique ?",
    de: "",
    ka: "გაუცდა თუ არა თქვენს შვილს სკოლა, იმის გამო რომ ჰქონდა ფიზიკური დისკომფორტი ?",
    fi: "Joutuiko lapsesi jäämään pois koulusta fyysisen vaivan vuoksi?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q3a = {
  type: "text",
  name: "q7s1q3a",
  title: {
    default: "If yes, how many days?",
    gr: "Αν ναι, πόσες μέρες;",
    fr: "Si oui, combien de jours ?",
    de: "",
    ka: "“კი“ პასუხის შემთხვევაში, რამდენი დღე?",
    fi: "Jos kyllä, kuinka monta päivää?",
  },
  inputType: "number",
  min: 1,
  defaultValue: 1,
  isRequired: isRequired,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 365",
      minValue: 1,
      maxValue: 365,
    },
  ],
  visibleIf: " {q7s1q3} == 'yes' ",
};

const s1q4 = {
  type: "radiogroup",
  name: "q7s1q4",
  title: {
    default: "Were symptoms associated with sleep disturbance?",
    gr: "Σχετίζονται τα συμπτώματα με προβλήματα στον ύπνο;",
    fr: "Les symptômes étaient-ils associés à des troubles du sommeil ?",
    de: "",
    ka: "იყო თუ არა სიმპტომები ძილის დარღვევებთან დაკავშირებული?",
    fi: "Olivatko oireet yhteydessä unihäiriöihin?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q4a = {
  type: "text",
  name: "q7s1q4a",
  title: {
    default: "If yes, how many days?",
    gr: "Αν ναι, πόσες μέρες;",
    fr: "Si oui, combien de jours ?",
    de: "",
    ka: "“კი“ პასუხის შემთხვევაში, რამდენი დღე?",
    fi: "Jos kyllä, kuinka monta päivää?",
  },
  inputType: "number",
  min: 1,
  defaultValue: 1,
  isRequired: isRequired,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 365",
      minValue: 1,
      maxValue: 365,
    },
  ],
  visibleIf: " {q7s1q4} == 'yes' ",
};

const s1q5 = {
  type: "matrixdropdown",
  name: "q7s1q5",
  title: {
    default: "Has your child had any of the following infections?",
    gr: "Είχε το παιδί σας καμία από τις παρακάτω λοιμώξεις;",
    fr: "L’enfant a-t-il/elle eu les infections suivantes ?",
    de: "",
    ka: "აღენიშნებოდა თუ არა თქვენს შვილს ქვემოთ მოყვანილი ინფექციები?",
    fi: "Oliko lapsellasi jotain seuraavista infektioista?",
  },
  horizontalScroll: true,
  columnMinWidth: "130px",
  columns: [
    yes_no,
    {
      name: "infection_times",
      title: {
        default: "Times",
        gr: "Πόσες φορές",
        fr: "Combien d’épisodes",
        de: "",
        ka: "რამდენჯერ",
        fi: "Kuinka monta kertaa",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 20,
      visibleIf: "{row.yes_no} == 'yes'",
      isRequired: isRequired,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 52",
          minValue: 1,
          maxValue: 52,
        },
      ],
    },
    {
      name: "infection_max_duration",
      title: {
        default: "Maximum Duration (days)",
        gr: "Μέγιστη διάρκεια (ημέρες)",
        fr: "Durée maximale (jours)",
        de: "",
        ka: "მაქს.ხანგრძლივობა(დღეები)",
        fi: "Pisin kesto",
      },
      cellType: "text",
      inputType: "number",
      min: 1,
      max: 365,
      visibleIf: "{row.yes_no} == 'yes'",
      isRequired: isRequired,
      validators: [
        {
          type: "numeric",
          text: "Value must be within the range of 1 to 365",
          minValue: 1,
          maxValue: 365,
        },
      ],
    },
  ],
  rows: [
    {
      value: "upper_respiratory",
      text: {
        default: "Upper respiratory infections (common colds)",
        gr: "Λοιμώξεις ανώτερου αναπνευστικού (κοινά κρυολογήματα)",
        fr: "Infections des voies respiratoires supérieures (rhumes)",
        de: "",
        ka: "ზედა სასუნთქი გზების ინფექცია (გაციება)",
        fi: "Ylempien hengitysteiden infektiot (flunssat)",
      },
    },
    {
      value: "lower_respiratory",
      text: {
        default: "Lower respiratory infections (pneumonia, bronchitis)",
        gr: "Λοιμώξεις κατώτερου αναπνευστικού (πνευμονία, βρογχίτιδα)",
        fr: "Infections des voies respiratoires inférieures (pneumopathie, bronchite)",
        de: "",
        ka: "ქვედა სასუნთქი გზების ინფექცია (პნევმონია, ბრონქიტი, გულმკერდის სხვა ინფექციები)",
        fi: "Alempien hengitysteiden infektiot (keuhkokuume, keuhkoputkentulehdus).",
      },
    },
    {
      value: "otitis",
      text: {
        default: "Otitis",
        gr: "Ωτίτιδα",
        fr: "Otite",
        de: "",
        ka: "ყურის ინფექცია",
        fi: "Korvatulehdus",
      },
    },
    {
      value: "gastroenteritis",
      text: {
        default: "Gastroenteritis (diarrhea/vomiting)",
        gr: "Γαστρεντερίτιδα (Διάρροια/Εμετός) ",
        fr: "Gastro-entérite (diarrhée/vomissements)",
        de: "",
        ka: "გასტროენტერიტი (დიარეა/ღებინება)",
        fi: "Gastroenteriitti (ripuli/oksentelu)",
      },
    },
    {
      value: "coronavirus",
      text: {
        default: "Coronavirus infection (COVID-19)",
        gr: "Λοίμωξη από κοροναϊό (COVID-19)",
        fr: "Infection à COVID-19",
        de: "",
        ka: "Covid ინფექცია",
        fi: "Koronavirus",
      },
    },
    {
      value: "staphylococcus",
      text: {
        default: "Staphylococcus diagnosed by nasal swab",
        gr: "Σταφυλόκοκκος που διαγνώστηκε με εξέταση από τη μύτη (ρινικό επίχρισμα)",
        fr: "Staphylocoque diagnostiqué par prélèvement nasal",
        de: "",
        ka: "სტაფილოკოკი, რომელიც დიაგნოზირებულია ცხვირის ნაცხის საშუალებით",
        fi: "Nenäpuikolla diagnosoitu stafylokokki",
      },
    },
    {
      value: "courses_of_antibiotics",
      text: {
        default: "Courses of antibiotics",
        gr: "Αντιβιοτική αγωγή",
        fr: "Cures d’antibiotiques",
        de: "",
        ka: "ანტიბიოტიკებით მკურნალობის კურსი",
        fi: "Antibioottikuurit",
      },
    },
  ],
};

const s1q6 = {
  type: "radiogroup",
  name: "q7s1q6",
  title: {
    default: "Has your child had any days with fever over 38°C?",
    gr: "Είχε το παιδί σας καμία μέρα πυρετό πάνω από 38°C;",
    fr: "Votre enfant a-t-il eu des jours de fièvre supérieure à 38°C ?",
    de: "",
    ka: "ჰქონია თუ არა თქვენს შვილს რომელიმე დღეს 38°C-ზე მაღალი ტემპერატურა? ",
    fi: "Onko lapsellasi ollut yli 38 °C:n kuumepäiviä?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q6a = {
  name: "q7s1q6a",
  type: "text",
  title: {
    default: "If yes, how many days?",
    gr: "Αν ναι, πόσες μέρες;",
    fr: "Si oui, combien de jours ?",
    de: "",
    ka: "“კი“ პასუხის შემთხვევაში, რამდენი დღე?",
    fi: "Jos kyllä, kuinka monta päivää?",
  },
  inputType: "number",
  min: 1,
  defaultValue: 1,
  isRequired: isRequired,
  validators: [
    {
      type: "numeric",
      text: "Value must be within the range of 1 to 365",
      minValue: 1,
      maxValue: 365,
    },
  ],
  visibleIf: "{q7s1q6} == 'yes' ",
};

const s1q7 = {
  type: "radiogroup",
  name: "q7s1q7",
  title: {
    default: "Has your child had any unscheduled visits to a doctor?",
    gr: "Είχε το παιδί σας καμιά μη προγραμματισμένη επισκέψη στο γιατρό;",
    fr: "Votre enfant a-t-il eu des visites imprévues chez le médecin ? ",
    de: "",
    ka: "დასჭირდა თუ არა თქვენს შვილს ექიმთად არაგეგმიური ვიზიტი?",
    fi: "Onko lapsellasi ollut suunnittelemattomia lääkärikäyntejä? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q7a = {
  name: "q7s1q7a",
  type: "text",
  title: {
    default: "If yes, for what reason?",
    gr: "Εάν ναι για ποιο λόγο;",
    fr: "Si oui, pour quelle raison ?",
    de: "",
    ka: "“კი“ პასუხის შემთხვევაში, რა იყო ამის მიზეზი?",
    fi: "Jos kyllä, mistä syystä?",
  },
  isRequired: isRequired,
  visibleIf: "{q7s1q7} == 'yes' ",
};

const s1q8 = {
  type: "radiogroup",
  name: "q7s1q8",
  title: {
    default: "Are there new pets or new contact with pets elsewhere?",
    gr: "Υπάρχουν καινούργια ζώα στο σπίτι ή επαφή με ζώα κάπου αλλού;",
    fr: "Y a-t-il de nouveaux animaux de compagnie ou de nouveaux contacts avec des animaux de compagnie ailleurs ?",
    de: "",
    ka: "ხომ არ გაიჩინეთ ახალი შინაური ცხოველები ან გქონიათ სადმე ახალი კონტაქტი შინაურ ცხოველებთან?",
    fi: "Onko muualla uusia lemmikkejä tai uusia kontakteja lemmikkeihin?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q8a = {
  type: "checkbox",
  name: "q7s1q8a",
  title: {
    default: "If yes, which pets? ",
    gr: "Αν ναι, ποια ζώα;",
    fr: "Si oui, quels animaux ?",
    de: "",
    ka: "თუ კი, რომელი შინაური ცხოველები?",
    fi: "Jos kyllä, mitkä lemmikit?",
  },
  isRequired: isRequired,
  colCount: 5,
  visibleIf: "{q7s1q8} == 'yes' ",
  choices: [
    {
      value: "dog",
      text: {
        default: "Dog",
        gr: "Σκύλος",
        fr: "Chien",
        de: "",
        ka: "ძაღლი",
        fi: "Koira",
      },
    },
    {
      value: "cat",
      text: {
        default: "Cat",
        gr: "Γάτα",
        fr: "Chat",
        de: "",
        ka: "კატა",
        fi: "Kissa",
      },
    },
    {
      value: "other_furry_pets",
      text: {
        default: "Other furry pets",
        gr: "Άλλα κατοικίδια ζώα με τρίχωμα",
        fr: "Autres animaux à fourrure",
        de: "",
        ka: "სხვა ბეწვიანი ცხოველები",
        fi: "Muita karvaisia lemmikkejä",
      },
    },
    {
      value: "bird",
      text: {
        default: "Bird",
        gr: "Πτηνό",
        fr: "Oiseau",
        de: "",
        ka: "ჩიტები",
        fi: "Lintu",
      },
    },
    {
      value: "other",
      text: {
        default: "Other",
        gr: "Άλλα",
        fr: "Autres",
        de: "",
        ka: "სხვა",
        fi: "Muita",
      },
    },
  ],
};

const s1q9 = {
  type: "radiogroup",
  name: "q7s1q9",
  title: {
    default: "Changes in the smoking status of the family?",
    gr: "Υπάρχουν αλλαγές όσον αφορά το κάπνισμα στην οικογένεια;",
    fr: "Y a-t-il eu des changements dans le statut tabagique de la famille ? ",
    de: "",
    ka: "შეიცვალა თუ არა თქვენს ოჯახში სიგარეტის მოხმარების სტატუსი?",
    fi: "Muutoksia perheen tupakointitilanteessa? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

const s1q9a = {
  type: "radiogroup",
  name: "q7s1q9a",
  title: {
    default: "If yes, has someone stopped smoking? ",
    gr: "Εάν ναι, έχει κάποιος διακόψει το κάπνισμα;",
    fr: "Si oui, quelqu'un a-t-il arrêté de fumer ?",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ოჯახის რომელიმე  წევრმა შეწყვიტა სიგარეტის მოწევა?",
    fi: "Jos kyllä, onko joku lopettanut tupakoinnin?",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s1q9} == 'yes' ",
};

const s1q9b = {
  type: "radiogroup",
  name: "q7s1q9b",
  title: {
    default: "Has someone started smoking in the house?",
    gr: "Έχει κάποιος ξεκινήσει το κάπνισμα στο σπίτι;",
    fr: "Quelqu'un a-t-il commencé à fumer dans la maison ? ",
    de: "",
    ka: "ოჯახის რომელიმე წევრმა დაიწყო სახლში სიგარეტის მოწევა?",
    fi: "Onko joku aloittanut tupakoinnin talossa? ",
  },
  isRequired: isRequired,
  choices: [yes, no],
  visibleIf: "{q7s1q9} == 'yes' ",
};

const s1q10 = {
  type: "radiogroup",
  name: "q7s1q10",
  title: {
    default:
      "Is there any mold or humidity in the house where your child lives?",
    gr: "Υπάρχει καθόλου μούχλα ή υγρασία στο σπίτι που μένει το παιδί σας; ",
    fr: "Y a-t-il de la moisissure ou de l'humidité dans la maison où vit votre enfant ?",
    de: "",
    ka: " სახლში, სადაც თქვენი შვილი ცხოვრობს, აღინიშნება თუ არა ობის ან ნესტის არსებობა?",
    fi: "Onko talossa, jossa lapsesi asuu, hometta tai kosteutta?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q10a = {
  type: "radiogroup",
  name: "q7s1q10a",
  visibleIf: "{q7s1q10} == 'yes' ",
  title: {
    default: "If yes, does this affect child’s symptoms?",
    gr: "Εάν ναι, επηρεάζει τα συμπτώματα του παιδιού σας;",
    fr: "Si oui, cela affecte-t-il les symptômes de votre enfant ?",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ახდენს თუ არა ეს გავლენას თქვენი შვილის სიმპტომებზე?",
    fi: "Jos kyllä, vaikuttaako tämä lapsen oireisiin?",
  },
  isRequired: isRequired,
  choices: [yes, no, dontknow],
};

const s1q10b = {
  type: "tagbox",
  name: "q7s1q10b",
  isRequired: isRequired,
  title: {
    default: "If yes, please indicate which symptoms are worse",
    gr: "Εάν ναι παρακαλώ προσδιορίστε ποια συμπτώματα είναι χειρότερα ",
    fr: "Si oui, veuillez indiquer quels symptômes sont les plus graves",
    de: "",
    ka: "თუ თქვენი პასუხია „კი“, ქვემოთ მოცემულთგან აღნიშნეთ თუ რომელი სიმპტომი (სიმპტომები)უარესდება? ",
    fi: "Jos vastasitte kyllä, ilmoittakaa, mitkä oireet ovat pahempia",
  },
  description: selectMultiple,
  visibleIf: "{q7s1q10a} == 'yes' ",
  choices: [
    {
      value: "cough",
      text: {
        default: "Cough",
        gr: "Βήχας",
        fr: "Toux",
        de: "",
        ka: "ხველა",
        fi: "Yskä",
      },
    },
    {
      value: "wheezing",
      text: {
        default: "Wheezing",
        gr: "Βράσιμο ή σφύριγμα στο στήθος",
        fr: "Sifflements",
        de: "",
        ka: "ხიხინი",
        fi: "Vinkuminen",
      },
    },
    {
      value: "difficulty_in_breathing",
      text: {
        default: "Difficulty in breathing",
        gr: "Δυσκολία στην αναπνοή",
        fr: "Difficulté à respire",
        de: "",
        ka: "სუნთქვის გაძნელება",
        fi: "Hengitysvaikeudet",
      },
    },
    {
      value: "runny_nose",
      text: {
        default: "Runny nose",
        gr: "Συνάχι",
        fr: "Nez qui coule",
        de: "",
        ka: "სურდო",
        fi: "Vuotava nenä",
      },
    },
    {
      value: "stuffy_nose",
      text: {
        default: "Stuffy nose",
        gr: "Μπουκωμένη μύτη",
        fr: "Nez bouché",
        de: "",
        ka: "ცხვირის გაჭედვა",
        fi: "Tukkoinen nenä",
      },
    },
    {
      value: "atopic_eczema",
      text: {
        default: "Atopic eczema",
        gr: "Ατοπικό έκζεμα",
        fr: "Dermatite atopique",
        de: "",
        ka: "ატოპიური ეგზემა",
        fi: "Atooppinen ihottuma",
      },
    },
  ],
};

const s1q11 = {
  type: "radiogroup",
  name: "q7s1q11",
  title: {
    default: "Moved to another residence?",
    gr: "Έχετε μετακομίσει σε καινούργιo τόπο κατοικίας;",
    fr: "Avez-vous déménagé dans une autre résidence ?",
    de: "",
    ka: "ხომ არ შეგიცვლიათ საცხოვრებელი ადგილი?",
    fi: "Onko lapsi muuttanut toiseen asuinpaikkaan?",
  },
  isRequired: isRequired,
  choices: [yes, no],
};

export const elementsS1 = [
  s1q1,
  s1q2,
  s1q2a,
  s1q3,
  s1q3a,
  s1q4,
  s1q4a,
  s1q5,
  s1q6,
  s1q6a,
  s1q7,
  s1q7a,
  s1q8,
  s1q8a,
  s1q9,
  s1q9a,
  s1q9b,
  s1q10,
  s1q10a,
  s1q10b,
  s1q11,
];
