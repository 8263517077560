import { Container } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import SurveyComponent from "./SurveyComponent";
import {
  postParentAnswers,
  updateParentAnswers,
} from "../../services/api/pquestionnaires.api";
import { useAppSelector, useAppThunkDispatch } from "../../store/hooks";
import {
  selectAllowAnswers,
  selectDrafts,
  selectQuestionnaires,
} from "../../store/selectors/questionnaireSelector";
import {
  Draft,
  ParentAnswers,
  ParentQuestionnaireAnswers,
} from "../../types/questionnaire";
import { getDrafts } from "../../store/effects/questionnaireEffects";
import { setAllowAnswers } from "../../store/actions/questionnaireActions";
import Loading from "../Loading";
import { postHealthConditionTags } from "../../services/api/users.api";
import { getHealthTags } from "../../utils/tags-functions";
import { handleApiError } from "../../utils/handleApiError";
import AlertDialog from "../Alert";
import { getRole, getUsername } from "../../services/api/utils.api";
import { ROLES } from "../../types/user";

const SurveyHandler = ({
  id,
  student,
  draft_id,
}: {
  id: string;
  student: string;
  draft_id?: string;
}) => {
  const drafts = useAppSelector(selectDrafts);
  const allow_answers = useAppSelector(selectAllowAnswers);
  const questionnaire = useAppSelector(selectQuestionnaires).find(
    (q) => q._id === id
  );
  const draft = drafts.find((q) => q._id === draft_id);
  const [answers, setAnswers] = useState<ParentAnswers>();
  const dispatch = useAppThunkDispatch();
  const [error, setError] = useState<string | undefined>();
  const [alert, setAlert] = useState<string | undefined>();
  const username = getUsername() as string;
  const role = getRole();

  // console.log("Draft:", draft);
  // console.log("Data:", answers);
  // console.log("Questionnaire: ", questionnaire);

  const saveData = async (
    data: ParentQuestionnaireAnswers,
    draft: boolean
  ): Promise<boolean> => {
    if (role !== ROLES.STUDENT && student === username) {
      return false;
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const qId = data["id"];
      const isBaseLine = qId === "1v2";
      data.username = role !== ROLES.STUDENT ? student : username;
      console.log("Tags", getHealthTags(data));
      console.log("SAVE:", data);

      if (role === ROLES.STUDENT) {
        data["_id"] = draft_id;
      }

      try {
        const res =
          role === ROLES.STUDENT
            ? await updateParentAnswers(data)
            : await postParentAnswers(data);

        if (res.status === 200) {
          if (!draft) {
            dispatch(setAllowAnswers({ ...allow_answers, [qId]: false }));
            isBaseLine &&
              postHealthConditionTags(data, data.username)
                .then((res) => {
                  if (res.status === 200) {
                    const message =
                      role === ROLES.STUDENT
                        ? "A new questionnaire is now available for you to complete."
                        : "The student is part of the nested cohort";
                    setAlert(message);
                  }
                })
                .catch((e) => {
                  handleApiError(
                    e,
                    setError,
                    " !!ERROR OCCURED!! Please contact your local administrator to have them activate the new case questionnaire, which will then be available for you to complete."
                  );
                });
          }
          await dispatch(getDrafts());
          resolve(true);
        } else {
          setAlert("Your data is not saved");
          resolve(false);
        }
      } catch (e) {
        console.error("An error occurred: ", e);
        handleApiError(e, setError);
        resolve(false);
      }
    });
  };

  useEffect(() => {
    console.log("Draft:", draft);
    if (draft) {
      console.log(draft);
      const {
        is_draft,
        last_updated,
        questionnaire_id,
        student,
        _id,
        ...draft_answers
      }: ParentAnswers = draft;
      // console.log("Answers: ", draft_answers);
      setAnswers(draft_answers);
    } else {
      setAnswers({});
    }
  }, [draft]);

  return (
    <Container maxWidth='xl'>
      {questionnaire && answers ? (
        <SurveyComponent
          questionnaire={questionnaire}
          answers={answers}
          saveData={saveData}
          hasDraft={role === ROLES.STUDENT}
        />
      ) : (
        <Loading />
      )}
      {(error || alert) && (
        <AlertDialog
          title={error ? "System Error" : "System Information"}
          message={error || alert || ""}
          buttons={[
            {
              label: "Ok",
              onClick: () => {
                error && setError(undefined);
                alert && setAlert(undefined);
              },
            },
          ]}
        />
      )}
    </Container>
  );
};

export default SurveyHandler;
