import { isRequired } from "../../consts/required";
import { no, symptomsChoices, yes } from "../../consts/yes_no";

const s6d1 = {
  type: "html",
  name: "subheading3",
  html: {
    default: "<h4>Have your child complete these questions.</h4>",
    gr: "<h4>Ζητείστε από το παιδί σας να απαντήσει στις παρακάτω ερωτήσεις.</h4>",
    fr: "<h4>Demandez à votre enfant de répondre à ces questions : </h4>",
    de: "<h4></h4>",
    ka: "<h4>კითხვები, რომლებზეც ბავშვებმა უნდა გასცენ</h4>",
    fi: "<h4>Pyydä lastasi vastaamaan näihin kysymyksiin.</h4>",
  },
};

const s6q1 = {
  type: "rating",
  name: "q7s6q1",
  isRequired: isRequired,
  rateType: "smileys",
  scaleColorMode: "colored",
  displayMode: "buttons",
  autoGenerate: false,
  title: {
    default: "How is your asthma today?",
    gr: "Πώς είναι το άσθμα σου σήμερα;",
    fr: "Comment va ton asthme aujourd’hui ?",
    de: "",
    ka: "როგორია დღეს შენი ასთმა?",
    fi: "Millainen astmasi on tänään?",
  },
  minRateDescription: {
    default: "Very bad",
    gr: "Πολύ άσχημα",
    fr: "Très mal",
    de: "",
    ka: "ძალიან ცუდ",
    fi: "Todella paha",
  },
  maxRateDescription: {
    default: "Very good",
    gr: "Πολύ καλά",
    fr: "Très bien",
    de: "",
    ka: "ძალიან კარგა",
    fi: "Todella hyvä",
  },
  rateMin: 0,
  rateCount: 4,
  rateMax: 3,
  rateValues: [
    {
      value: 0,
      text: {
        default: "Very bad",
        gr: "Πολύ άσχημα",
        fr: "Très mal",
        de: "",
        ka: "ძალიან ცუდ",
        fi: "Todella paha",
      },
    },
    {
      value: 1,
      text: {
        default: "Bad",
        gr: "Άσχημα",
        fr: "Mal",
        de: "",
        ka: "ცუდ",
        fi: "Paha",
      },
    },
    {
      value: 2,
      text: {
        default: "Good",
        gr: "Kαλά",
        fr: "Βien",
        de: "",
        ka: "კარგა",
        fi: "Hyvä",
      },
    },
    {
      value: 3,
      text: {
        default: "Very good",
        gr: "Πολύ καλά",
        fr: "Très bien",
        de: "",
        ka: "ძალიან კარგა",
        fi: "Todella hyvä",
      },
    },
  ],
};

const s6q2 = {
  type: "rating",
  name: "q7s6q2",
  isRequired: isRequired,
  title: {
    default:
      "How much of a problem is your asthma when you run, excersice or play sports?",
    gr: "Πόσο σε ενοχλεί το άσθμα σου όταν τρέχεις, γυμνάζεσαι ή κάνεις αθλητισμό;",
    fr: "Quel type de problème représente ton asthme quand tu cours, que tu fais de l’exercice ou du sport ?",
    de: "",
    ka: "რამდენად დიდი პრობლემაა შენთვის ასთმა, როდესაც დარბიხარ, ვარჯიშობ ან დაკავებული ხარ სპორტით?",
    fi: "Kuinka paljon sinulle on haittaa astmastasi, kun juokset, pelaat tai harrastat muuta liikuntaa?",
  },
  minRateDescription: {
    default: "It's a big problem, I can't do what I want to do.",
    gr: "Με ενοχλεί πολύ. Δεν μπορώ να κάνω αυτά που θέλω.",
    fr: "C’est un gros problème, je ne peux pas faire ce que je veux",
    de: "",
    ka: "დიდი პრობლემაა, ვერ ვაკეთებ იმას რისი გაკეთებაც",
    fi: "Siitä on paljon haittaa, en pysty tekemään mitä haluaisin.",
  },
  maxRateDescription: {
    default: "It's not a problem",
    gr: "Δεν με ενοχλεί.",
    fr: "Ce n’est pas un problème",
    de: "",
    ka: "ეს არ არის პრობლ",
    fi: "Siitä ei ole haittaa.",
  },
  rateType: "smileys",
  scaleColorMode: "colored",
  rateMin: 0,
  rateCount: 4,
  rateValues: [
    {
      value: 0,
      text: {
        default: "It's a big problem, I can't do what I want to do.",
        gr: "Με ενοχλεί πολύ. Δεν μπορώ να κάνω αυτά που θέλω.",
        fr: "C’est un gros problème, je ne peux pas faire ce que je veux",
        de: "",
        ka: "დიდი პრობლემაა, ვერ ვაკეთებ იმას რისი გაკეთებაც",
        fi: "Siitä on paljon haittaa, en pysty tekemään mitä haluaisin.",
      },
    },
    {
      value: 1,
      text: {
        default: "It's a problem and I don't like it.",
        gr: "Με ενοχλεί και δεν μου αρέσει.",
        fr: "C'est un problème et je n'aime pas ça.",
        de: "",
        ka: "პრობლემაა და არ მომწონს.",
        fi: "Se on ongelma, enkä pidä siitä.",
      },
    },
    {
      value: 2,
      text: {
        default: "It's a little problem but it's okay.",
        gr: "Με ενοχλεί λίγο αλλά δεν με πειράζει.",
        fr: "C'est un petit problème mais ça va.",
        de: "",
        ka: "ცოტა პრობლემაა, მაგრამ არაუშავს.",
        fi: "Se on pieni ongelma, mutta ei haittaa.",
      },
    },
    {
      value: 3,
      text: {
        default: "It's not a problem",
        gr: "Δεν με ενοχλεί.",
        fr: "Ce n’est pas un problème",
        de: "",
        ka: "ეს არ არის პრობლ",
        fi: "Siitä ei ole haittaa.",
      },
    },
  ],
  rateMax: 3,
  displayMode: "buttons",
};

const s6q3 = {
  type: "rating",
  name: "q7s6q3",
  isRequired: isRequired,
  title: {
    default: "Do you cough because of your asthma",
    gr: "Σε κάνει το άσθμα σου να βήχεις;",
    fr: "Ton asthme te fait-il tousser ?",
    de: "",
    ka: "ახველებ ასთმის გამო?",
    fi: "Yskitkö astmasi vuoksi?",
  },
  minRateDescription: {
    default: "Yes all the time",
    gr: "Ναι, συνέχεια.",
    fr: "Oui, tout le temps",
    de: "",
    ka: "დიახ, მუდმივა",
    fi: "Kyllä, koko ajan",
  },
  maxRateDescription: {
    default: "No, none of the time",
    gr: "Όχι, ποτέ.",
    fr: "Non, jamais",
    de: "",
    ka: "არასდრო",
    fi: "Ei, en koskaan.",
  },
  rateType: "smileys",
  scaleColorMode: "colored",
  rateMin: 0,
  rateCount: 4,
  rateMax: 3,
  rateValues: [
    {
      value: 0,
      text: {
        default: "Yes all the time",
        gr: "Ναι, συνέχεια.",
        fr: "Oui, tout le temps",
        de: "",
        ka: "დიახ, მუდმივა",
        fi: "Kyllä, koko ajan",
      },
    },
    {
      value: 1,
      text: {
        default: "Yes, most of the time.",
        gr: "Ναι, πολύ συχνά.",
        fr: "Oui, la plupart du temps.",
        de: "",
        ka: "დიახ, უმეტესად.",
        fi: "Kyllä, suurimman osan ajasta.",
      },
    },
    {
      value: 2,
      text: {
        default: "Yes, some of the time.",
        gr: "Ναι, μερικές φορές.",
        fr: "Oui, de temps en temps.",
        de: "",
        ka: "დიახ, ზოგჯერ.",
        fi: "Kyllä, joskus.",
      },
    },
    {
      value: 3,
      text: {
        default: "No, none of the time",
        gr: "Όχι, ποτέ.",
        fr: "Non, jamais",
        de: "",
        ka: "არასდრო",
        fi: "Ei, en koskaan.",
      },
    },
  ],
  displayMode: "buttons",
};

const s6q4 = {
  type: "rating",
  name: "q7s6q4",
  title: {
    default: "Do you wake up during the night because of your asthma?",
    gr: "Σε κάνει το άσθμα σου να ξυπνάς τη νύχτα;",
    fr: "Ton asthme te réveille-t-il la nuit ?",
    de: "",
    ka: "გეღვიძება ღამით ასთმის გამო?",
    fi: "Heräätkö yöllä astmasi takia?",
  },
  minRateDescription: {
    default: "Yes all of the time",
    gr: "Ναι, συνέχεια.",
    fr: "Oui, tout le temps",
    de: "",
    ka: "დიახ, მუდმივა",
    fi: "Kyllä, koko ajan.",
  },
  maxRateDescription: {
    default: "No, none of the time",
    gr: "Όχι, ποτέ.",
    fr: "Non, jamais",
    de: "",
    ka: "არასდროს.",
    fi: "Ei, en koskaan.",
  },
  rateType: "smileys",
  scaleColorMode: "colored",
  rateMin: 0,
  rateCount: 4,
  rateMax: 3,
  rateValues: [
    {
      value: 0,
      text: {
        default: "Yes all the time",
        gr: "Ναι, συνέχεια.",
        fr: "Oui, tout le temps",
        de: "",
        ka: "დიახ, მუდმივა",
        fi: "Kyllä, koko ajan",
      },
    },
    {
      value: 1,
      text: {
        default: "Yes, most of the time.",
        gr: "Ναι, πολύ συχνά.",
        fr: "Oui, la plupart du temps.",
        de: "",
        ka: "დიახ, უმეტესად.",
        fi: "Kyllä, suurimman osan ajasta.",
      },
    },
    {
      value: 2,
      text: {
        default: "Yes, some of the time.",
        gr: "Ναι, μερικές φορές.",
        fr: "Oui, de temps en temps.",
        de: "",
        ka: "დიახ, ზოგჯერ.",
        fi: "Kyllä, joskus.",
      },
    },
    {
      value: 3,
      text: {
        default: "No, none of the time",
        gr: "Όχι, ποτέ.",
        fr: "Non, jamais",
        de: "",
        ka: "არასდრო",
        fi: "Ei, en koskaan.",
      },
    },
  ],
  displayMode: "buttons",
};

const s6d2 = {
  type: "html",
  name: "subheading4",
  html: {
    default: "<h4>Please complete the following questions on your own.</h4>",
    gr: "<h4>Παρακαλούμε απαντήστε μόνος/η σας στις παρακάτω ερωτήσεις.</h4>",
    fr: "<h4>Répondez vous-même aux trois questions suivantes sans vous laisser influencer par les réponses de votre enfant. Il n’y a pas de bonne ou de mauvaise réponse.</h4>",
    de: "<h4></h4>",
    ka: "<h4>კითხვები რომლებსაც მშობელმა უნდა გასცეს პასუხი</h4>",
    fi: "<h4>Vastaa itse seuraaviin kysymyksiin.</h4>",
  },
};

const s6q5 = {
  type: "radiogroup",
  name: "q7s6q5",
  colCount: 2,
  title: {
    default:
      "During the last 4 weeks, on average, how many days per month did your child have any daytime asthma symptoms?",
    gr: "Πόσες ημέρες μέσα στις τελευταίες 4 εβδομάδες παρουσίασε το παιδί σας συμπτώματα άσθματος κατά τη διάρκεια της ημέρας;",
    fr: "Au cours des quatre dernières semaines, combien de jours votre enfant a-t-il présenté des symptômes d’asthme pendant la journée ?",
    de: "",
    ka: " ბოლო 4 კვირის განმავლობაში, საშუალოდ, რამდენი დღე იყო თვეში ისეთი, როცა თქვენს შვილს გამოხატული ჰქონდა ასთმის სიმპტომები?",
    fi: "Kuinka monena päivänä viimeksi kuluneiden 4 viikon aikana lapsellasi oli astman oireita päiväsaikaan?",
  },
  isRequired: isRequired,
  choices: symptomsChoices,
};

const s6q6 = {
  type: "radiogroup",
  name: "q7s6q6",
  colCount: 2,
  title: {
    default:
      "During the last 4 weeks, on average, how many days per month did your child wheeze during the day because of asthma?",
    gr: "Πόσες ημέρες μέσα στις τελευταίες 4 εβδομάδες παρουσίασε το παιδί σας σφύριγμα στην αναπνοή λόγω του άσθματος κατά τη διάρκεια της ημέρας;",
    fr: "Au cours des quatre dernières semaines, combien de jours votre enfant a-t-il présenté des symptômes d’asthme pendant la journée ?",
    de: "",
    ka: "ბოლო 4 კვირის განმავლობაში, საშუალოდ რამდენი დღე იყო თვეში ისეთი, როცა თქვენს შვილს აღენიშნებოდა მსტვინავი სუნთქვა ასთმის გამო?",
    fi: "Kuinka monena päivänä viimeksi kuluneiden 4 viikon aikana lapsesi hengitys vinkui päivällä astman vuoksi?",
  },
  isRequired: isRequired,
  choices: symptomsChoices,
};

const s6q7 = {
  type: "radiogroup",
  name: "q7s6q7",
  colCount: 2,
  title: {
    default:
      "During the last 4 weeks, on average, how many days per month did your child wake up during the night because of asthma?",
    gr: "Πόσες ημέρες μέσα στις τελευταίες 4 εβδομάδες ξύπνησε το παιδί σας μέσα στη νύχτα λόγω του άσθματος;",
    fr: "Au cours des quatre dernières semaines, combien de nuits votre enfant s’est-il réveillé à cause de son asthme ?",
    de: "",
    ka: "ბოლო 4 კვირის განმავლობაში, საშუალოდ რამდენი დღე იყო თვეში ისეთი, როცა თქვენმა შვილმა გაიღვიძა ღამით ასთმის გამო?",
    fi: "Kuinka monena päivänä viimeksi kuluneiden 4 viikon aikana lapsesi heräsi yön aikana astman vuoksi?",
  },
  isRequired: isRequired,
  choices: symptomsChoices,
};
export const elementsS6 = [
  s6d1,
  s6q1,
  s6q2,
  s6q3,
  s6q4,
  s6d2,
  s6q5,
  s6q6,
  s6q7,
];
