import { elementsS1 } from "./partA/section1";
import { elementsS2 } from "./partB/section2";
import { elementsS3 } from "./partB/section3";
import { elementsS4 } from "./partC/section4";
import { elementsS5 } from "./partC/section5";
import { elementsS6 } from "./partC/section6";
import { elementsS7 } from "./partD/section7";

export const questionnaire7 = {
  id: "7",
  preview: "/q7preview.png",
  checkErrorsMode: "onValueChanged",
  active: {
    Greece: {
      from: "01/01/2024",
      to: "01/01/2024",
    },
  },
  answersNum: 3,
  title: {
    default: "Follow up questionnaire",
    gr: "Παρακολούθηση",
    fr: "Questionnaire de suivi",
    de: "",
    ka: "დაკვირვების მონაცემთა  ფორმა",
    fi: "Seurantakysely",
  },
  showQuestionNumbers: "on",
  description: {
    default: "Follow up questionnaire",
    gr: "Παρακολούθηση για υγιή παιδιά",
    fr: "Questionnaire de suivi",
    de: "",
    ka: "დაკვირვების მონაცემთა  ფორმა",
    fi: "Seurantakysely",
  },
  pages: [
    {
      name: "section1",
      title: {
        default: "In the past three months:",
        gr: "Τους τελευταίους 3 μήνες:",
        fr: "Au cours des trois derniers mois:",
        de: "",
        ka: "ბოლო სამი თვის განმავლობაში:",
        fi: "Viimeiset kolme kuukautta:",
      },
      elements: elementsS1,
    },
    {
      visibleIf: "{q7s1q11} = 'yes'",
      name: "section2",
      title: {
        default: "Exposures ",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default:
          "Child’s room/home. In this section we ask a number of questions on your child’s home. For each question, please provide answers for the home in which your child lives at present, and for the home in which your child lived during the first year of life. (In case you have moved, please choose the home in which your child spent most of his or her time during the first year of life). Please make sure that you tick both columns! ",
        gr: "Το δωμάτιο/σπίτι του παιδιού. Σε αυτήν την ενότητα θέλουμε να απαντήσετε μια σειρά ερωτήσεων σχετικά με το σπίτι του παιδιού σας. Για κάθε ερώτηση, παρακαλώ να δώσετε απαντήσεις για το σπίτι στο οποίο διαμένει σήμερα και για το σπίτι στο οποίο διέμενε κατά τη διάρκεια του πρώτου χρόνου της ζωής του. (Σε περίπτωση που έχετε μετακομίσει, παρακαλώ επιλέξτε το σπίτι στο οποίο το παιδί σας πέρασε τον περισσότερο χρόνο κατά τον πρώτο χρόνο της ζωής του). Παρακαλούμε σιγουρευτείτε ότι έχετε σημειώσει τις απαντήσεις σας και στις δύο στήλες!",
        fr: "Chambre/domicile de l’enfant. Dans cette section, nous posons un certain nombre de questions sur le foyer de votre enfant. Pour chaque question, veuillez répondre pour le foyer dans lequel votre enfant vit actuellement et pour le foyer dans lequel il a vécu au cours de sa première année de vie. (Si vous avez déménagé, veuillez choisir le domicile dans lequel votre enfant a passé la majeure partie de son temps au cours de sa première année de vie). Veillez à cocher les deux colonnes!",
        de: "",
        ka: "ბავშვის ოთახი/სახლი. ამ ნაწილში წარმოდგენილი კითხვები  თქვენი შვილის  საცხოვრებელი სახლის შესახებ. გთხოვთ, გვიპასუხოთ კითხვებზე, რომლებიც ეხება იმ საცხოვრებელ სახლს, სადაც თქვენი შვილი ამჟამად ცხოვრობს და ასევე, იცხოვრა დაბადებიდან პირველი წლის განმავლობაში (იმ შემთხვევაში, თუ თქვენ შეიცვალეთ საცხოვრებელი, გთხოვთ პასუხებისთვის აირჩიოთ ის სახლი, სადაც ბავშვმა გაატარა მეტი დრო დაბადებიდან  პირველ წელს). გთხოვთ,მიაქციოთყურადღება, რომ მონიშნული იყოს ორივე სვეტი!",
        fi: "Tässä osiossa kysymme useita kysymyksiä lapsesi kodista. Vastatkaa kuhunkin kysymykseen siitä kodista, jossa lapsenne asuu tällä hetkellä, ja siitä kodista, jossa lapsenne asui ensimmäisen elinvuotensa aikana. (Jos olette muuttaneet, valitkaa se koti, jossa lapsenne vietti suurimman osan ajastaan ensimmäisen elinvuotensa aikana). Varmistaa, että rastitatte molempiin sarakkeisiin!",
      },
      elements: elementsS2,
    },
    {
      visibleIf: "{q7s1q11} = 'yes'",
      name: "section3",
      title: {
        default: "Exposures",
        gr: "Εκθέσεις",
        fr: "Expositions",
        de: "",
        ka: "გარემო ფაქტორებით ექსპოზიცია",
        fi: "Altistukset",
      },
      description: {
        default: "Child’s home building",
        gr: "Το κτίριο του σπιτιού που μένει το παιδί σας",
        fr: "Construction de la maison de l’enfant",
        de: "",
        ka: "ბავშვის საცხოვრებელი შენობა",
        fi: "Lapsen kotirakennus",
      },
      elements: elementsS3,
    },
    {
      name: "section4",
      title: {
        default: "Asthma",
        gr: "Άσθμα",
        fr: "Asthme",
        de: "",
        ka: "ასთმა",
        fi: "Astma",
      },
      elements: elementsS4,
    },
    {
      name: "section5",
      title: {
        default: "Asthma Control Questionnaire",
        gr: "Ερωτηματολόγιο για τον έλεγχο του άσθαματος",
        fr: "Questionnaire de contrôle de l’asthme (ACQ-6)",
        de: "",
        ka: "ასთმის კონტროლის კითხვარი.",
        fi: "Astman kontrollikysely",
      },
      description: {
        default:
          "Select the number of the response that best describes how you have been during the past week.",
        gr: "Σκεφθείτε πως ήσαστε την ΠΕΡΑΣΜΕΝΗ ΕΒΔΟΜΑΔΑ και επιλέξτε τον αριθμό της απάντησης σας που περιγράφει καλύτερα την κατάσταση σας.",
        fr: "Répond aux 6 questions suivantes, en cochant la réponse qui décrit le mieux comment tu as été au cours de la semaine dernière.",
        de: "",
        ka: "გთხოვთ უპასუხოთ კითხვებს 1-6. აირჩიეთ ის რიცხვი, რომელიც ყველაზე მეტად შეესაბამება  ბოლო კვირის განმავლობაში თქვენს მდგომარეობას",
        fi: "Valitse kuvan numero, joka kuvaa parhaiten sitä, miten olet voinut kuluneen viikon aikana.",
      },
      elements: elementsS5,
      visibleIf: "{q7s4q1} == 'yes'",
    },
    {
      name: "section6",
      title: {
        default:
          "Childhood Asthma Control Test for children 4 to 11 years old.",
        gr: "Τεστ για τον έλεγχο του παιδικού άσθματος για παιδιά ηλικίας 4 ως 11 ετών",
        fr: "Test de contrôle de l’asthme chez l’enfant (cACT) – 4-11 ans",
        de: "",
        ka: "ბავშვთა ასთმის კონტროლის ტესტი 4-დან 11 წლამდე ბავშვებისთვის.",
        fi: "Lasten astmatesti 4-11-vuotiaille lapsille.",
      },
      description: {
        default:
          "Let your child respond to the first four questions (1 to 4). If your child \
        needs help reading or understanding the question, you may help, but let your \
        child select the response. Complete the remaining three questions (5 to 7) on your \
        own wihout letting your child's reponse influence your answers. There are \
        no right or wrong answers.",
        gr: "Αφήστε το παιδί σας να απαντήσει στις πρώτες τέσσερις ερωτήσεις (1 έως 4). Αν το \
        παιδί σας χρειαστεί βοήθεια για να διαβάσει ή να καταλάβει κάποια ερώτηση μπορείτε να το \
        βοηθήσετε, αρκεί να το αφήσετε να διαλέξει μόνο του την απάντηση. Απαντήστε μόνος/η σας \
        στις υπόλοιπες τρεις ερωτήσεις (5 έως 7), χωρίς να αφήσετε να σας επηρεάσουν οι απαντήσεις \
        του παιδιού σας. Δεν υπάρχουν σωστές ή λάθος απαντήσεις.",
        fr: "Demandez à votre enfant de répondre à ces questions ",
        de: "",
        ka: "თქვენმა შვილმა პასუხი უნდა გასცეს პირველ 4 შეკითხვას (1 - 4). იმ შემთხვევაში, თუ თქვენი\
        შვილი საჭიროებს დახმარებას ტექსტის წაკითხვაში ან შეკითხვის გააზრებაში, თქვენ მას უნდა \
        დაეხმაროთ, მაგრამ პასუხები ბავშვმა დამოუკიდებლად უნდა აირჩიოს. თქვენ თვითონ შეავსეთ დარჩენილი\
        3 კითხვა (მე-5-დან მე-7-მდე) ისე, რომ ბავშვის აზრმა არ იმოქმედოს თქვენს პასუხებზე. გაითვალისწინეთ,\
        რომ სწორი ან მცდარი პასუხები არ არსებობს.",
        fi: "Anna lapsesi itse vastata neljään ensimmäiseen kysymyk-seen (1–4). Jos lapsesi tarvitsee apua\
        kysymysten lukemi-sessa tai ymmärtämisessä, voit auttaa, mutta anna lapsesi valita vastaus. \
        Vastaa kolmeen jäljellä olevaan kysymyk-seen (5–7) yksin, äläkä anna lapsesi vastausten vaikuttaa \
        omiin vastauksiisi. Oikeita tai vääriä vastauksia ei ole.",
      },
      elements: elementsS6,
      visibleIf: "{q7s4q1} == 'yes'",
    },
    {
      name: "section7",
      title: {
        default: "Rhinitis",
        gr: "Ρινίτιδα",
        fr: "Rhinite",
        de: "",
        ka: "რინიტი",
        fi: "Nuha",
      },
      elements: elementsS7,
    },
  ],
};
